html {
    font-size: 125%;
    scroll-behavior: smooth;
}

.wrapper {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
}

body {
    background: lighten($secondaryAccent,40%);
    font-size: 1rem;
    color: $mainDark;
    font-family: $mainFont;
}

body > div, .App {
    min-height: 100vh;
}

.App {
    display: flex;
    flex-direction: column;
    > div {
        flex-grow: 1;
    }
}

header {
    padding: 40px 0;
    background: darken($secondaryAccent,20%);
    // Background image from https://www.transparenttextures.com/
    background-image: url(../assets/bright-squares.png);
    color: $mainLight;
    h1 {
        font-size: 2rem;
        text-align: center;
        text-shadow: 3px 3px 0 $mainAccent;
    }
}

h1,h2,h3, button {
    font-family: $secondaryFont;
    font-weight: normal;
}

main {
    padding: 20px 0;
}

form {
    display: flex;
    flex-wrap: wrap;
    &.questionForm {
        margin-bottom: 30px;
    }
    &.answerForm {
        margin: 30px 0;
    }
    label {
        font-family: $secondaryFont;
        display: block;
        width: 100%;
    }
    input {
        size: 1;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 5px 10px;
        border: none;
    }
}

button {
    background: $mainAccent;
    color: $mainDark;
    border: $mainAccent 2px solid;
    padding: 5px 20px;
    transition: 0.3s;
    &:hover, &:focus {
        color: $mainAccent;
        background: $mainDark;
    }
    &:disabled {
        color: $mainDark;
        background: lighten($mainDark,50%);
        border-color: lighten($mainDark,50%);
    }
}

ul {
    li {
        display: flex;
        margin: 20px 0;
        align-items: stretch;
        transition: 0.1s;
    }
}

ul.questionList {
    li {
        position: relative;
        background: lighten($secondaryAccent,30%);
        box-shadow: 10px 10px 0 $secondaryAccent;
        top: 0;
        left: 0;
        &:hover, &:focus-within {
            box-shadow: 12px 12px 0 $secondaryAccent;
            left: -2px;
            top: -2px;
        }
    }
}

ul.questionThread {
    li {
        background: darken($secondaryAccent,20%);
        box-shadow: 10px 10px 0 $secondaryAccent;
        color: $mainLight;
    }
}

.textContent {
    flex-grow: 1;
    a {
        display: block;
        width: 100%;
        transition: 0.3s;
        &:hover, &:focus {
            color: $mainAccent;
        }
    }
    a, &.answerText {
        padding: 10px;
    }
}

.voteKnob {
    display: flex;
    align-items: stretch;
    background: $mainLight;
    color: $mainDark;
    p {
        align-self: center;
        padding: 0 5px;
        text-align: center;
    }
    button {
        padding: 2px 10px;
    }
    p, button {
        min-width: calc(2rem);
    }
}
.returnButton {
    display: block;
    margin: 20px auto;
}

h2 {
    margin: 20px 0;
}

footer {
    padding: 40px 0;
    background: darken($secondaryAccent,20%);
    // Background image from https://www.transparenttextures.com/
    background-image: url(../assets/bright-squares.png);
    color: $mainLight;
    text-align: center;
    text-shadow: 1px 1px 0 $mainAccent;
}