@keyframes moveDown {
    from {
        transform: translateY(calc(-100% - 20px));
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes moveUp {
    from {
        transform: translateY(calc(100% + 20px));
    }
    to {
        transform: translateY(0%);
    }
}

.moveUp {
    animation: 0.3s moveUp;
}

.moveDown {
    animation: 0.3s moveDown;
}