// Stipping styles
h1, h2, h3, p {
    margin: 0;
}

ul,li {
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}